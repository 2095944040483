// extracted by mini-css-extract-plugin
export var news = "_news-module--news--x6B9M";
export var news__wrap = "_news-module--news__wrap--xIlKH";
export var news__title = "_news-module--news__title--9w4hP";
export var news__category_list = "_news-module--news__category_list--1XwAd";
export var news__category_list__active = "_news-module--news__category_list__active--5IILI";
export var news__list = "_news-module--news__list--y+Bzj";
export var news__list_card = "_news-module--news__list_card--lyk2e";
export var news__boxwrap = "_news-module--news__boxwrap--3GaoE";
export var news__set = "_news-module--news__set--UY8n+";
export var news__newscategory = "_news-module--news__newscategory--fXNGk";
export var news__newstitle = "_news-module--news__newstitle---Pzc6";