import * as React from 'react';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import * as Styles from '../styles/pages/_news.module.scss';
import { dayjs } from '../lib/dayjs';
import Pagination from '../components/pagination';
import { BreadCrumb } from '../components/breadcrumb';
import { BackGround } from '../components/background';
import { isNewsTypeColor } from '../lib/is-news-type-color';
import {Link, useIntl} from "gatsby-plugin-react-intl"
import { contentPathHelper } from '../utils/contentPathHelper';

export default function News({ pageContext, location }) {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const {
    nodes,
    category: currentCategory,
    categories,
    prevPagePath,
    nextPagePath,
    pagination,
    currentPageNum,
    totalPageNum,
    // totalCount,
  } = pageContext;

  /** 2ページ以上ページネーションが存在すれば true */
  const isPageNation = React.useMemo(() => totalPageNum > 1, [totalPageNum]);

  const [isCategory] = React.useState(currentCategory);

  return (
    <Layout location={location}>
      <Seo title='お知らせ | LIMITS (リミッツ) ｜ 競技型デジタルアート' description='LIMITSの最新情報はこちら！' />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
        ]}
        currentPage={t('menu.news')}
      />
      <BackGround>
        <section className={Styles.news}>
          <div className={Styles.news__wrap}>
            <div className={Styles.news__title}>
              <h1>{t('menu.news')}</h1>
            </div>

            {/* カテゴリー一覧 */}
            <ul className={Styles.news__category_list}>
              <li className={!isCategory && Styles.news__category_list__active}>
                <Link to='/news/'>
                  <p>ALL</p>
                </Link>
              </li>
              {categories.map((category, index) => (
                <li key={category.slug + index} className={isCategory?.slug === category.slug && Styles.news__category_list__active}>
                  <Link to={`/news/category/${contentPathHelper(category.slug)}/`}>
                    <p>{category.title}</p>
                  </Link>
                </li>
              ))}
            </ul>

            <ul className={Styles.news__list}>
              {nodes.length > 0 ? ( nodes.map((node) => (
                <li key={node.id} className={Styles.news__list_card}>
                  {!node.redirect_url ? (
                    <Link to={`/news/${contentPathHelper(node.id)}/`}>
                      {node.image?.url !== undefined && <img src={node.image.url + '?w=620&q=80&fm=webp'} alt='' />}
                      <div className={Styles.news__boxwrap}>
                        <div className={Styles.news__set}>
                          {dayjs(node.publishedAt).format('YYYY/MM/DD')}
                          <p className={Styles.news__newscategory} style={{ backgroundColor: isNewsTypeColor(node.type.title) }}>
                            {node.type.title}
                          </p>
                        </div>
                        <p className={Styles.news__newstitle}>{node.title}</p>
                      </div>
                    </Link>
                  ) : (
                    <a href={node.redirect_url} target='_blank' rel='noopener'>
                      {node.image?.url !== undefined && <img src={node.image.url + '?w=620&q=80&fm=webp'} alt='' />}
                      <div className={Styles.news__boxwrap}>
                        <div className={Styles.news__set}>
                          {dayjs(node.publishedAt).format('YYYY/MM/DD')}
                          <p className={Styles.news__newscategory} style={{ backgroundColor: isNewsTypeColor(node.type.title) }}>
                            {node.type.title}
                          </p>
                        </div>
                        <p className={Styles.news__newstitle}>{node.title}</p>
                      </div>
                    </a>
                  )}
                </li>
              ))) : (
                <li>
                  <p className={Styles.news__boxwrap}>{t('news.noPosting')}</p>
                </li>
              )}
            </ul>
          </div>
        </section>
      </BackGround>
      {/* pagination */}
      {isPageNation && <Pagination currentPageNum={currentPageNum} pagination={pagination} prevPagePath={prevPagePath} nextPagePath={nextPagePath} />}
    </Layout>
  );
}
