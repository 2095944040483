import React from 'react';
import { Arrow } from './components/arrow';
import * as Styles from '../../styles/components/_pagination.module.scss';
import classNames from 'classnames';
import {Link, useIntl} from "gatsby-plugin-react-intl"
import { Arrows } from './components/arrows';

export default function Pagination(props) {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const {
    currentPageNum = 1, // 現在のページ
    pagination = [], // ページ一覧情報
    prevPagePath = null, // 前のページの情報
    nextPagePath = null, // 次のページの情報
  } = props;

  // 「最初へ戻る」と「最後へ戻る」のリンクを何ページ以上離れれば表示させるか。
  const firstAndLastPerPage = 5;
  const maxPageLinks = 5;

  // 最後のページから比べて何ページ存在するのか
  const compareWithEnd = pagination.length - currentPageNum;

  // 現在のページから何ページ話して表示させたいか
  const pageNeighbours = 2;

  const paginationStart = currentPageNum - pageNeighbours > 1 ? currentPageNum - pageNeighbours : 1;

  const filterPagination = () => {
    if (pagination.length < 5) {
      return pagination;
    }

    if (pagination.length - currentPageNum < pageNeighbours + 1) {
      return pagination.slice(-5);
    }

    // ページネーションから現在のページの左右二つ前後のページを切り出す。
    return paginationStart >= pageNeighbours ? pagination.slice(paginationStart - 1, maxPageLinks + paginationStart - 1) : pagination.slice(paginationStart - 1, 5);
  };

  return (
    <>
      <div className={Styles.pagination}>
        <ul>
          {prevPagePath && (
            <Link className={classNames(Styles.pagination__link, Styles.pagination__link__prev)} to={prevPagePath}>
              <Arrow />
            </Link>
          )}

          {filterPagination().map((v) => (
            <li key={v.pageNum}>
              {v.pageNum === currentPageNum ? (
                <span className={Styles.pagination__current_link}>{v.pageNum}</span>
              ) : (
                <Link to={v.path} className={classNames(Styles.pagination__link, { [Styles.pagination__current_link]: currentPageNum === v.pageNum })}>
                  {v.pageNum}
                </Link>
              )}
            </li>
          ))}

          {nextPagePath && compareWithEnd > firstAndLastPerPage && (
            <li>
              <span className={classNames(Styles.pagination__link)}>...</span>
            </li>
          )}

          {nextPagePath && (
            <>
              <li>
                <Link className={classNames(Styles.pagination__link)} to={nextPagePath}>
                  <Arrow />
                </Link>
              </li>

              {compareWithEnd > firstAndLastPerPage && (
                <li>
                  <Link className={classNames(Styles.pagination__link)} to={pagination.slice(-1)[0].path} aria-label={t('pagination.lastPage')} >
                    <Arrows />
                  </Link>
                </li>
              )}
            </>
          )}
        </ul>
      </div>
    </>
  );
}
